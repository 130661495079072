const itemAdapter = (data) => {
  return {
    text: data.text,
    title: data.title,
    subtext: data?.subtext || null,
  };
};

const partnerAdapter = (data) => {
  return {
    title: data.title,
  };
};

export default ({ data }) => {
  return {
    items: data.items?.map(itemAdapter),
    partners: data.partners?.map(partnerAdapter),
  };
};
